import React, { useEffect, useRef } from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import WOW from 'wowjs';

import { useParallax } from 'react-scroll-parallax';
import ServiceInclude from './ServiceInclude';
import { Link } from 'react-router-dom';
import Whychooseus from '../../component/Whychooseus';
import InnerImage from '../../assets/images/products/erw-pipes/inner-banner.webp';
export default function TradeMarketing() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <section className='inner-banner' style={{ backgroundImage: `url(${InnerImage})` }}>

                <h1 className='heading'>Erw Tubes And Pipes</h1>
                <div className='container'>
                    <p style={{ textAlign: 'center' }}>ERW (Electric Resistance Welded) tubes and pipes are commonly used in various industrial applications.<br /> They are produced through a welding process where steel is formed into tubes and welded using electric resistance, without the need for filler material.<br /> Below is some detailed content related to ERW tubes and pipes</p>
                </div>
                {/* <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item"><Link to="/product">Products</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Erw Tubes &amp; Pipes</li>
                    </ol>
                </nav> */}
            </section>


            <section className='about-section-4 bottom_100px top padding-left-right team-section-1' style={{ background: '#0b164e' }}>
                <div className='container'>
                    <div className='service-detail-left-side'>
                        <h3 className='sub-heading bottom text-center' style={{ color: 'white' }}> OUR PRODUCT</h3>

                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='three-images-container'>
                                    <div className='first-img-box'>
                                        <img src={require('../../assets/images/products/erw-tubes.webp')} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='service-details-right-content'>
                                    <p className="with-line">ERW tubes and pipes are widely used across industries. In the oil and gas sector, they are crucial for pipelines due to their corrosion resistance and pressure handling. In automotive, they are vital for frames and exhaust systems. In construction, they serve structural frameworks, scaffolding, and reinforcement.</p>
                                    <p className="with-line">The production of ERW tubes and pipes ensures consistent quality, reduces material waste, and minimizes production time. This leads to cost savings and improved efficiency, with uniformity in manufacturing contributing to the overall reliability and performance of the final product.</p>
                                    <p className="with-line">ERW tubes and pipes are often treated or coated to improve corrosion resistance, extending their service life. This protection makes them suitable for indoor and outdoor use, ensuring reliable performance in environments exposed to moisture and chemicals.</p>
                                    <p className="with-line">The lightweight nature of ERW tubes and pipes simplifies their transportation and handling on-site. Their ease of fabrication and installation also contributes to faster project completion times. This practicality makes them a convenient choice for many construction and industrial projects.</p>
                                    <p className='with-line'>ERW tubes and pipes come in various sizes, thicknesses, and lengths, offering customization for specific needs. This versatility makes them ideal for structural supports, fluid transportation, and diverse applications. Their adaptability ensures suitability across many contexts, from construction to oil and gas industries.</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-lg-4'>
                            <ServiceInclude />
                        </div> */}
                    </div>
                    <h3 className='main-heading' style={{ color: 'white' }}>Features of Erw Tubes and Pipes</h3>
                    <p style={{ color: 'white' }}><strong>Durability:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>ERW pipes are made from high-quality steel, which is then subjected to a process where it is heated and welded using electric resistance. This makes them strong and capable of withstanding high pressures and temperatures.</li>
                        {/* <li style={{ color: 'white' }}><i class="fa-solid fa-check"></i> Collaborative Planning: Our team collaborates with you and your trade partners to develop strategic trade marketing initiatives aligned with your goals.</li>
                        <li style={{ color: 'white' }}><i class="fa-solid fa-check"></i> Creative Execution: We leverage our creative expertise to design eye-catching point-of-sale materials, promotional displays, and other marketing collateral.</li> */}
                    </ul>

                    <p style={{ color: 'white' }}><strong>Cost-Effectiveness:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>The manufacturing process of ERW pipes is less complex and more efficient compared to other methods like seamless pipes. This results in lower production costs, making ERW pipes a cost-effective option.</li>
                    </ul>
                    <p style={{ color: 'white' }}><strong>Consistent Quality:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>The production process ensures a high level of consistency in wall thickness and diameter, contributing to reliable performance.</li>
                    </ul>
                    <p style={{ color: 'white' }}><strong>Weld Integrity:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>The electric resistance welding process ensures a strong bond without the need for additional filler materials, resulting in high-quality welds that are uniform and reliable.</li>
                    </ul>
                    <p style={{ color: 'white' }}><strong>Versatility: </strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>ERW pipes can be manufactured in various sizes and wall thicknesses, making them suitable for a wide range of applications. </li>
                    </ul>
                </div>
            </section>

            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container'>
                    <div className='product-contact-redirect'>
                        <Link to="/contact">
                            <img src={require('../../assets/images/products/contact.webp')} alt="" />
                            <h3 className='main-heading-inquery'>Inquiry Now</h3>
                        </Link>
                    </div>
                </div>
            </section>

        </>
    )
}
