import React, { useEffect } from "react";
import { Parallax, useParallax } from 'react-scroll-parallax';

const Plank = () => {

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <section ref={parallax.ref} className="about-section-4 bottom padding-left-right team-section-1">
                <div className="container">
                    <div className="value-product-image-logo">
                        <img src={require('../../assets/images/value_added/apollo_alpha.webp')} />
                    </div>
                    <hr class="wavy-divider" />
                    <div className="row">
                        <div className="col-md-5">
                            <div className="product-value-image">
                                <img src={require('../../assets/images/value_added/window-l-type.webp')} alt="single door" />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="product-value-right">
                                <h4 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '30px', color: '#00207b' }}>WINDOW FRAME L</h4>
                                <h6 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '20px' }}>SPECIFICATIONS</h6>
                                <div className='slider-para wow fadeInUp' style={{ textAlign: 'left', color: '#000', margin: '0px' }}>
                                    Window L Section – Size: 46x18mm, 52x24mm, 62x33mm<br />
                                    Steel door frames,
                                    Window frames
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="wavy-divider" />
                    <div className="row">
                        <div className="col-md-7">
                            <div className="product-value-right">
                                <h4 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '30px', color: '#00207b' }}>WINDOW FRAME T</h4>
                                <h6 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '20px' }}>SPECIFICATIONS</h6>
                                <div className='slider-para wow fadeInUp' style={{ textAlign: 'left', color: '#000', margin: '0px' }}>
                                    Window T Section –Size: 52x25mm<br />
                                    Steel door frames,
                                    Window frames
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="product-value-image">
                                <img src={require('../../assets/images/value_added/T_SECTION.webp')} alt="double door" />
                            </div>
                        </div>

                    </div>
                    <hr class="wavy-divider" />
                    <div className="row">
                        <div className="col-md-5">
                            <div className="product-value-image">
                                <img src={require('../../assets/images/value_added/window-z-type.webp')} alt="single door" />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="product-value-right">
                                <h4 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '30px', color: '#00207b' }}>WINDOW FRAME Z</h4>
                                <h6 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '20px' }}>SPECIFICATIONS</h6>
                                <div className='slider-para wow fadeInUp' style={{ textAlign: 'left', color: '#000', margin: '0px' }}>
                                    Window Z Section – Size: 41x24mm<br />
                                    Steel door frames,
                                    Window frames
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Plank;