import React, { useEffect, useRef } from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import WOW from 'wowjs';
import { useParallax } from 'react-scroll-parallax';
import ServiceInclude from './ServiceInclude';
import { Link } from 'react-router-dom';
import Whychooseus from '../../component/Whychooseus';
import InnerImage from '../../assets/images/products/ms-angle-banner.webp';

export default function MsAngle() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <section className='inner-banner' style={{ backgroundImage: `url(${InnerImage})` }}>
                <h1 className='heading'>MS Angle</h1>
                <div className='container'>
                    <p style={{ textAlign: 'center' }}>MS Angles are L-shaped mild steel components commonly used in construction and fabrication.<br />
                        They provide structural reinforcement, support beams, and frames, and are versatile for various applications<br /> like building structures, supports, and brackets. Their shape enhances load distribution and stability.</p>
                </div>
            </section>

            <section className='about-section-4 bottom_100px top padding-left-right team-section-1' style={{ background: '#0b164e' }}>
                <div className='container'>
                    <div className='service-detail-left-side'>
                        <h3 className='sub-heading bottom text-center' style={{ color: 'white' }}> OUR PRODUCT</h3>

                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='three-images-container'>
                                    <div className='first-img-box'>
                                        <img src={require('../../assets/images/products/ms-angle-in.webp')} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='service-details-right-content'>
                                    <p className="with-line">MS Angles are typically L-shaped, with two perpendicular legs that form a right angle. This design provides a high degree of stability and support. The legs can vary in length and thickness, allowing for customization based on structural requirements.</p>
                                    <p className="with-line">Hot-rolled angles are made by rolling heated steel billets, enhancing structural properties. Cold-formed angles are created by bending cold steel, providing varied tolerances and surface finishes.</p>
                                    <p className="with-line">MS Angles can be coated or painted for corrosion resistance and appearance. Common finishes are galvanizing for zinc protection and painting for aesthetics and added protection.</p>
                                    <p className="with-line">Overall, MS Angles are integral to construction and fabrication, providing essential support, reinforcement, and structural stability across various applications.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className='main-heading' style={{ color: 'white' }}>Features of MS Angle</h3>
                    <p style={{ color: 'white' }}><strong>Durability:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>MS Angles are made from mild steel, which offers robust durability and strength. Their ability to withstand significant loads and resist deformation ensures they perform reliably over time, even in challenging conditions.</li>
                    </ul>

                    <p style={{ color: 'white' }}><strong>Cost-Effectiveness:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>Mild steel is a cost-effective material compared to alternatives. MS Angles provide a high strength-to-cost ratio, making them an economical choice for structural reinforcement and support in construction and fabrication.</li>
                    </ul>
                    <p style={{ color: 'white' }}><strong>Consistent Quality:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>MS Angles are manufactured to precise specifications and standards, ensuring consistent quality in terms of dimensions, strength, and finish. This reliability helps maintain structural integrity and performance across various applications.</li>
                    </ul>
                    <p style={{ color: 'white' }}><strong>Weld Integrity:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>The weldability of mild steel allows MS Angles to be easily welded, ensuring strong and reliable joints. This property facilitates secure connections in frameworks and supports, contributing to overall structural stability.</li>
                    </ul>
                    <p style={{ color: 'white' }}><strong>Versatility: </strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>MS Angles are highly versatile, suitable for a wide range of applications from structural supports and frames to brackets and reinforcements. Their adaptability to different configurations and uses makes them valuable in diverse construction and fabrication projects. </li>
                    </ul>
                </div>
            </section>

            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container'>
                    <div className='product-contact-redirect'>
                        <Link to="/contact">
                            <img src={require('../../assets/images/products/contact.webp')} alt="" />
                            <h3 className='main-heading-inquery'>Inquiry Now</h3>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}
