import React, { useEffect } from "react";
import { Parallax, useParallax } from 'react-scroll-parallax';


const Chaukhat = () => {
    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section ref={parallax.ref} className="about-section-4 bottom padding-left-right team-section-1">
                <div className="container">
                    <div className="value-product-image-logo">
                        <img src={require('../../assets/images/value_added/chaukhat.webp')} />
                    </div>
                    <hr class="wavy-divider" />
                    <div className="row">
                        <div className="col-md-5">
                            <div className="product-value-image">
                                <img src={require('../../assets/images/value_added/single door frame.webp')} alt="single door" />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="product-value-right">
                                <h4 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '30px', color: '#00207b' }}>SINGLE DOOR</h4>
                                <h6 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '20px' }}>SPECIFICATIONS</h6>
                                <div className='slider-para wow fadeInUp' style={{ textAlign: 'left', color: '#000', margin: '0px' }}>
                                    Single Door Frames<br />
                                    Sizes: 100 mm X 50 mm<br />
                                    Thickness: 1.4 mm -2.5 mm<br />
                                    Doors and Windows
                                </div>

                            </div>
                        </div>
                    </div>
                    <hr class="wavy-divider" />
                    <div className="row">
                        <div className="col-md-7">
                            <div className="product-value-right">
                                <h4 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '30px', color: '#00207b' }}>DOUBLE DOOR</h4>
                                <h6 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '20px' }}>SPECIFICATIONS</h6>
                                <div className='slider-para wow fadeInUp' style={{ textAlign: 'left', color: '#000', margin: '0px' }}>
                                    Double Door Frames<br />
                                    Sizes: 130 mm X 65 mm 75 mm X 65 mm<br />
                                    Thickness: 1.4 mm – 2.5 mm<br />
                                    Doors and Windows
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="product-value-image">
                                <img src={require('../../assets/images/value_added/double door frame.webp')} alt="double door" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}


export default Chaukhat;