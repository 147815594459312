import React, { useEffect, useRef } from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import WOW from 'wowjs';

import { useParallax } from 'react-scroll-parallax';
import ServiceInclude from './ServiceInclude';
import { Link } from 'react-router-dom';
import Whychooseus from '../../component/Whychooseus';
import InnerImage from '../../assets/images/products/coil-banner.webp';

export default function HrCoil() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <section className='inner-banner' style={{ backgroundImage: `url(${InnerImage})` }}>

                <h1 className='heading'>HR Coil</h1>
                <div className='container'>
                    <p style={{ textAlign: 'center' }}>HR (Hot-Rolled) coils are steel coils made by rolling steel at high temperatures, offering flexibility and strength.<br />
                        They are widely used in construction for structural components, in automotive for parts like frames,<br /> and in manufacturing for creating machinery, storage tanks, and pipes due to their durability and ease of forming.</p>
                </div>
            </section>

            <section className='about-section-4 bottom_100px top padding-left-right team-section-1' style={{ background: '#0b164e' }}>
                <div className='container'>
                    <div className='service-detail-left-side'>
                        <h3 className='sub-heading bottom text-center' style={{ color: 'white' }}> OUR PRODUCT</h3>

                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='three-images-container'>
                                    <div className='first-img-box'>
                                        <img src={require('../../assets/images/products/coil-in.webp')} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='service-details-right-content'>
                                    <p className="with-line">HR Coils are produced through the hot rolling process, where steel is heated above its recrystallization temperature and then passed through rollers to achieve the desired thickness. This method allows the steel to be shaped more easily and results in coils with excellent structural integrity.</p>
                                    <p className="with-line"><b>Strength and Durability:</b> HR Coils provide exceptional tensile strength and are capable of withstanding heavy loads and harsh conditions, making them ideal for structural applications.</p>
                                    <p className="with-line"><b>Flexibility:</b> Due to the hot rolling process, HR Coils maintain good flexibility, allowing them to be easily shaped and formed into various configurations. This property is particularly valuable in industries that require custom components, such as automotive and manufacturing.</p>
                                    <p className="with-line"><b>Surface Finish:</b> While HR Coils have a slightly rougher surface compared to cold-rolled coils, they can be further processed (e.g., galvanized, painted, or coated) to improve corrosion resistance and surface quality.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className='main-heading' style={{ color: 'white' }}>Features of HR Coils</h3>
                    <p style={{ color: 'white' }}><strong>Durability:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>HR Coils offer exceptional strength and durability, making them ideal for applications that require resistance to heavy loads, impact, and environmental wear. Their robust nature ensures long-lasting performance in demanding industrial settings.</li>
                    </ul>

                    <p style={{ color: 'white' }}><strong>Cost-Effectiveness:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>The hot rolling process used to produce HR Coils is efficient and economical. This makes them a cost-effective option for large-scale projects, providing high-quality material at a competitive price without sacrificing performance.</li>
                    </ul>
                    <p style={{ color: 'white' }}><strong>Consistent Quality:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>HR Coils are manufactured under controlled conditions to ensure consistent thickness, dimensional accuracy, and uniformity. This reliability guarantees a steady supply of high-quality material for industries that demand precision and dependability.</li>
                    </ul>
                    <p style={{ color: 'white' }}><strong>Weld Integrity:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>HR Coils have excellent weldability, allowing for strong, secure, and reliable joints during fabrication. This makes them suitable for applications that require welding, such as construction, automotive, and heavy machinery manufacturing.</li>
                    </ul>
                    <p style={{ color: 'white' }}><strong>Versatility: </strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>HR Coils are available in a wide range of sizes, thicknesses, and grades, offering flexibility for diverse applications. They can be easily shaped, cut, and customized to meet specific project needs, making them adaptable for construction, automotive, and manufacturing industries. </li>
                    </ul>
                </div>
            </section>

            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container'>
                    <div className='product-contact-redirect'>
                        <Link to="/contact">
                            <img src={require('../../assets/images/products/contact.webp')} alt="" />
                            <h3 className='main-heading-inquery'>Inquiry Now</h3>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}
