import React from "react";
import { Link } from "react-router-dom";
import InnerImage from '../../assets/images/value-added.png';


const VAlueAddedProduct = () => {

    return (
        <>
            <section className='inner-banner' style={{ backgroundImage: `url(${InnerImage})` }}>
                <h1 className='heading'>Value Added Product</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Value Added Product</li>
                    </ol>
                </nav>
            </section>
            <section className='about-section-4 bottom padding-left-right team-section-1' style={{ background: 'rgb(250 249 249)' }}>
                <div className='container'>
                    <div className='header-title header-center mb-0'>
                        <h2 className='heading'>Value Added Product</h2>
                    </div>
                    <div className='row bottom top'>
                        <div className="col-lg-3 col-md-6 col-12">
                            <Link to="/chaukhat" style={{ textDecoration: 'none' }}>
                                <div className='value-product-logo'>
                                    <img src={require('../../assets/images/value_added/Chaukhat_Application.webp')} alt="" />
                                    <h4 style={{ textAlign: 'center', fontSize: '19px' }}>APOLLO CHAUKHAT</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <Link to="/plank" style={{ textDecoration: 'none' }}>
                                <div className='value-product-logo'>
                                    <img src={require('../../assets/images/value_added/Plank_Application.webp')} alt="" />
                                    <h4 style={{ textAlign: 'center', fontSize: '19px' }}>APOLLO PLANK</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <Link to="/alpha" style={{ textDecoration: 'none' }}>
                                <div className='value-product-logo'>
                                    <img src={require('../../assets/images/value_added/Alpha_Application.webp')} alt="" />
                                    <h4 style={{ textAlign: 'center', fontSize: '19px' }}>APOLLO ALPHA</h4>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <Link to="/reflector" style={{ textDecoration: 'none' }}>
                                <div className='value-product-logo'>
                                    <img src={require('../../assets/images/value_added/Application.webp')} alt="" />
                                    <h4 style={{ textAlign: 'center', fontSize: '19px' }}>APOLLO REFLECTORS</h4>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default VAlueAddedProduct;