import React, { useEffect } from "react";
import { Parallax, useParallax } from 'react-scroll-parallax';

const Plank = () => {

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section ref={parallax.ref} className="about-section-4 bottom padding-left-right team-section-1">
                <div className="container">
                    <div className="value-product-image-logo">
                        <img src={require('../../assets/images/value_added/plank.webp')} alt="plank" />
                    </div>
                    <hr class="wavy-divider" />
                    <div className="row">
                        <div className="col-md-5">
                            <div className="product-value-image">
                                <img src={require('../../assets/images/value_added/reflector-image.webp')} alt="single door" />
                            </div>
                        </div>
                        <div className="col-md-7 top">
                            <div className="product-value-right">
                                <h4 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '30px', color: '#00207b' }}>PLANK</h4>
                                <h6 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '20px' }}>SPECIFICATIONS</h6>
                                <div className='slider-para wow fadeInUp' style={{ textAlign: 'left', color: '#000', margin: '0px' }}>
                                    Sizes: 125X25mm to 275X25mm,<br />
                                    Thickness: 1.6mm and 2.0mm,<br />
                                    Available in black and pre-galvanized.
                                    Other thicknesses are also available on request<br />
                                    Fence:
                                    Staircase:
                                    Gates:
                                    Scaffoldings:
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Plank;