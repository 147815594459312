import React, { useEffect } from "react";
import { Parallax, useParallax } from 'react-scroll-parallax';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation, Keyboard } from 'swiper/modules';


const MsSheet = () => {

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section ref={parallax.ref} className="about-section-4 top bottom padding-left-right team-section-1">
                <div className="container">
                    <div className="value-product-image-logo">
                        <h4 className='sub-heading wow fadeInUp' style={{ color: '#00207b' }}>MS Sheet & Checkered Sheet</h4>
                    </div>
                    <hr class="wavy-divider" />
                    <div className="row">
                        <div className="col-md-5">
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={10}
                                pagination={{
                                    clickable: true,
                                }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper marketing-slider"
                            >
                                <SwiperSlide>

                                    <div className="product-value-image">
                                        <img src={require('../../assets/images/home-product/ms-sheet/ms-shee.webp')} alt="single door" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="product-value-image">
                                        <img src={require('../../assets/images/home-product/ms-sheet/checkered.webp')} alt="single door" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="product-value-image">
                                        <img src={require('../../assets/images/home-product/ms-sheet/ms-sheet-slide-1.webp')} alt="single door" />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                        <div className="col-md-7 top">
                            <div className="product-value-right top">
                                <h4 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '30px', color: '#00207b' }}>MS Sheet & Checkered Sheet</h4>
                                <h6 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '20px' }}>SPECIFICATIONS</h6>
                                <div className='slider-para wow fadeInUp' style={{ textAlign: 'left', color: '#000', margin: '0px' }}>
                                    Size : 1250mm to 2500mm<br />
                                    Thickness : 1.6 mm to 20 mm<br />

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className='about-section-4 bottom padding-left-right team-section-1' style={{ background: 'rgb(250 249 249)' }}>
                <div className='container'>
                    <div className='header-title header-center mb-0'>
                        <h2 className='heading'>PRODUCT APPLICATIONS</h2>
                    </div>
                    <div className='row bottom'>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/ms-sheet/ms-sheet-2.webp')} alt="ms-sheet-2" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/ms-sheet/ms-pipe-3.webp')} alt="ms-sheet-3" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/ms-sheet/ms-sheet-4.webp')} alt="ms-sheet-4" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/ms-sheet/checkered-1.webp')} alt="ms-sheet-4" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/ms-sheet/ms-sheet-1.webp')} alt="ms-sheet-4" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/ms-sheet/ms-sheet-3.webp')} alt="ms-sheet-4" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MsSheet;