import React, { useEffect } from "react";
import InnerImage from '../assets/images/csr-banner.webp';
import { Link } from 'react-router-dom';
import { Parallax, useParallax } from 'react-scroll-parallax';

const CSRInitiative = () => {

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className='inner-banner' style={{ backgroundImage: `url(${InnerImage})` }}>
                <h1 className='heading'>CSR Initiative</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">CSR Initiative</li>
                    </ol>
                </nav>
            </section>

            <section className='top privacy_po bottom padding-left-right about-section-1 '>
                <div className='container'>
                    <div className='row'>
                        <h4 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '30px', color: '#00207b' }}>CSR Overview</h4>
                        <div className="csr-initiative">
                            <p>
                                Responsibility serves as the cornerstone for sustainable growth, involving the protection and nurturing of ecosystems, people, and policies that support continuous progress. Similarly, Corporate Social Responsibility (CSR) is about contributing to societal goals through philanthropic activities, charitable endeavors, and professional service volunteering, such as pro-bono programs.<br />
                                CSR extends beyond profit distribution; it also includes the ethical practices involved in generating revenue within an organization.<br />
                                At Vikash Steel, we are committed to giving back to the community through sustainable and innovative CSR initiatives. Our CSR vision and mission focus on building a stronger community by empowering and uplifting the lives of widowed women. This is achieved through year-round programs dedicated to supporting and promoting the well-being of widows.
                            </p>
                            <p>Vikash Steel Tube Pvt Ltd has always believed in the philosophy that businesses grow when they give back to society. As part of its Corporate Social Responsibility (CSR) initiatives, the company is proud to contribute to <strong>Apna Ghar Ashram</strong>, a shelter providing aid to the underprivileged, elderly, and homeless individuals. Apna Ghar Ashram, known for its compassionate service to those in need, offers a safe environment, food, medical care, and rehabilitation to the vulnerable sections of society. Vikash Steel Tube Pvt Ltd’s collaboration with the Ashram is a testament to its commitment to community development and social welfare. Through regular donations and support, Vikash Steel Tube Pvt Ltd ensures that Apna Ghar Ashram continues to provide essential services. The company’s CSR initiatives focus on empowering communities and improving the quality of life for those who need it most. This partnership symbolizes the company's dedication to creating a positive social impact, ensuring that their contributions create long-lasting benefits for society at large. By aligning its business success with community well-being, Vikash Steel Tube Pvt Ltd continues to set an example of corporate responsibility and ethical business practices.</p>
                        </div>
                    </div>
                </div>
                <div className="container top">
                    <div className='row bottom'>
                        <h4 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '30px', color: '#00207b' }}>Our Gallery</h4>
                        <h5 className="heading">Not applicable!!</h5>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CSRInitiative;