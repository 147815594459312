import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css'
import './assets/js/script.js'
import './assets/fontIcon/css/all.min.css'
// import Router from './route/Router';
import { ParallaxProvider } from 'react-scroll-parallax';
import { useEffect, useState } from 'react';
import Router from './route/Router.js';


function App() {

  return (
    <ParallaxProvider>
      <div className="wrapper">
        <Router />
      </div>
    </ParallaxProvider>
  );
}

export default App;

// import { useLoadScript } from "@react-google-maps/api";
// import Map from "./pages/GoogleMaps";
// // import "./styles.css";

// export default function App() {
//   const { isLoaded } = useLoadScript({
//     googleMapsApiKey: "" // Add your API key
//   });

//   return isLoaded ? <Map /> : null;
// }

