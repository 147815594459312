import React, { useEffect } from "react";
import { Parallax, useParallax } from 'react-scroll-parallax';

const ColourCoatedProducts = () => {

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section ref={parallax.ref} className="about-section-4 top bottom padding-left-right team-section-1">
                <div className="container">
                    <div className="value-product-image-logo">
                        <h4 className='sub-heading wow fadeInUp' style={{ color: '#00207b' }}>Colour Coated Products</h4>
                    </div>
                    <hr className="wavy-divider" />
                    <div className="row">
                        <div className="col-md-5">
                            <div className="product-value-image">
                                <img src={require('../../assets/images/home-product/coated/colour-coated.webp')} alt="coated-sheet" />
                            </div>
                        </div>
                        <div className="col-md-7 top">
                            <div className="product-value-right top">
                                <h4 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '30px', color: '#00207b' }}>Colour Coated Sheet</h4>
                                <h6 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '20px' }}>SPECIFICATIONS</h6>
                                <div className='slider-para wow fadeInUp' style={{ textAlign: 'left', color: '#000', margin: '0px' }}>
                                    Colour Coated Sheet,
                                    Paint System - 4 Coat System,
                                    Paint Coating - 30 µm Paint Coating,
                                    Thickness - 0.25 mm to l.20 mm,
                                    Width - Upto 1440 mm,
                                    Metallic Coating - AZ-70,
                                    Tensile Strength - Upto 550 Mpa<br />
                                    Residential Roofs|
                                    Industrial Sheds|
                                    Warehouse Sheds|
                                    Commercial Buildings
                                </div>
                                {/* <div className='faq-section-inner'>
                                    <div class="accordion wow fadeInUp" id="accordionExample">
                                        <div className='faq-box'>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        How does Vikash Steel tailor its marketing
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        We conduct extensive research to understand rural consumers' behavior, preferences, and aspirations, allowing us to create targeted and culturally relevant campaigns.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        What types of channels and mediums for
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        We employ a mix of traditional channels such as local events, fairs, and community gatherings, along with modern mediums like mobile outreach and digital platforms customized for rural contexts.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        How does ommunities, considering diverse languages and cultures?
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        We employ local language communication and culturally sensitive messaging to ensure resonance and authenticity, facilitating better engagement and connection with rural audiences.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <hr class="wavy-divider" />
                    <div className="row">
                        <div className="col-md-7">
                            <div className="product-value-right top">
                                <h4 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '30px', color: '#00207b' }}>Colour Coated Coil</h4>
                                <h6 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '20px' }}>SPECIFICATIONS</h6>
                                <div className='slider-para wow fadeInUp' style={{ textAlign: 'left', color: '#000', margin: '0px' }}>
                                    Colour Coated Coil<br />
                                    Paint System - 4 Coat System
                                    Paint Coating - 30 µm Paint Coating
                                    Thickness - 0.25 mm to l.20 mm
                                    Width - Upto 1440 mm
                                    Metallic Coating - AZ-150
                                    Tensile Strength - Upto 550 Mpa<br />
                                    Residential Roofs|
                                    Industrial Sheds|
                                    Warehouse Sheds|
                                    Commercial Buildings
                                </div>
                                {/* <div className='faq-section-inner'>
                                    <div class="accordion wow fadeInUp" id="accordionExample">
                                        <div className='faq-box'>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseOne">
                                                        How does Vikash Steel tailor its marketing
                                                    </button>
                                                </h2>
                                                <div id="collapseFour" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        We conduct extensive research to understand rural consumers' behavior, preferences, and aspirations, allowing us to create targeted and culturally relevant campaigns.
                                                        <img src={require('../../assets/images/home-product/coated/coloure.webp')} alt="coloure" />

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseTwo">
                                                        What types of channels and mediums for
                                                    </button>
                                                </h2>
                                                <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        We employ a mix of traditional channels such as local events, fairs, and community gatherings, along with modern mediums like mobile outreach and digital platforms customized for rural contexts.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseThree">
                                                        How does ommunities, considering diverse languages and cultures?
                                                    </button>
                                                </h2>
                                                <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        We employ local language communication and culturally sensitive messaging to ensure resonance and authenticity, facilitating better engagement and connection with rural audiences.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="product-value-image">
                                <img src={require('../../assets/images/home-product/coated/colour-coated-coil.webp')} alt="coil" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className='about-section-4 bottom padding-left-right team-section-1' style={{ background: 'rgb(250 249 249)' }}>
                <div className='container'>
                    <div className='header-title header-center mb-0'>
                        <h2 className='heading'>PRODUCT APPLICATIONS</h2>
                    </div>
                    <div className='row bottom top'>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/coated/coated-4.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/coated/coated-2.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/coated/coated-3.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/coated/product-jpeg-500x500.webp')} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default ColourCoatedProducts;