import React, { useEffect } from "react";
import { Parallax, useParallax } from 'react-scroll-parallax';

const StructuralSteelTubesPipes = () => {

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section ref={parallax.ref} className="about-section-4 top bottom padding-left-right team-section-1">
                <div className="container">
                    <div className="value-product-image-logo">
                        <h4 className='sub-heading wow fadeInUp' style={{ color: '#00207b' }}>Structural Steel Tubes & Pipes</h4>
                    </div>
                    <hr class="wavy-divider" />
                    <div className="row">
                        <div className="col-md-5">
                            <div className="product-value-image">
                                <img src={require('../../assets/images/home-product/squire-inner.webp')} alt="squire" />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="product-value-right top">
                                <h4 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '30px', color: '#00207b' }}>Square Hollow Section</h4>
                                <h6 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '20px' }}>SPECIFICATIONS</h6>
                                <div className='slider-para wow fadeInUp' style={{ textAlign: 'left', color: '#000', margin: '0px' }}>
                                    Product Range: 20mm x 20mm to 500mm x 500mm <br />
                                    Thickness: 0.6mm to 20mm<br />
                                    Architectural Applications|
                                    Industrial Applications|
                                    Infrastructural Applications|
                                    General Engineering|
                                    Agricultural Applications|
                                    Agricultural Implements
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="wavy-divider" />
                    <div className="row">
                        <div className="col-md-7">
                            <div className="product-value-right top">
                                <h4 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '30px', color: '#00207b' }}>Rectangular Hollow Section</h4>
                                <h6 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '20px' }}>SPECIFICATIONS</h6>
                                <div className='slider-para wow fadeInUp' style={{ textAlign: 'left', color: '#000', margin: '0px' }}>
                                    Product range : 26mm x 13mm to 600mm x 400mm <br />
                                    Thickness : 0.6mm to 20mm<br />
                                    Architectural Applications|
                                    Industrial Applications|
                                    Infrastructural Applications|
                                    General Engineering|
                                    Agricultural Applications|
                                    Agricultural Implements
                                </div>

                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="product-value-image">
                                <img src={require('../../assets/images/home-product/rectangular.webp')} alt="double door" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className='about-section-4 bottom padding-left-right team-section-1' style={{ background: 'rgb(250 249 249)' }}>
                <div className='container'>
                    <div className='header-title header-center mb-0'>
                        <h2 className='heading'>PRODUCT APPLICATIONS</h2>
                    </div>
                    <div className='row bottom top'>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/structural/strutrural-1.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/structural/strutural-2.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/structural/strutural-3.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src="https://media.licdn.com/dms/image/C5612AQEmgTsQroNZaQ/article-cover_image-shrink_720_1280/0/1640172135420?e=2147483647&v=beta&t=viVYE6sLOadAdM7XlpkrajTE2qMG_xcTHPSQWrNGL7s" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default StructuralSteelTubesPipes;