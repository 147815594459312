import React, { useEffect } from "react";
import { Parallax, useParallax } from 'react-scroll-parallax';

const GsPipes = () => {

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (
        <>
            <section ref={parallax.ref} className="about-section-4 top bottom padding-left-right team-section-1">
                <div className="container">
                    <div className="value-product-image-logo">
                        <h4 className='sub-heading wow fadeInUp' style={{ color: '#00207b' }}>Pre Galvanized Tubes & Pipes</h4>
                    </div>
                    <hr class="wavy-divider" />
                    <div className="row">
                        <div className="col-md-5">
                            <div className="product-value-image">
                                <img src={require('../../assets/images/home-product/gp-pipes/gp-pipes.webp')} alt="gp-pipes" />
                            </div>
                        </div>
                        <div className="col-md-7 top">
                            <div className="product-value-right">
                                {/* <h4 className='sub-heading wow fadeInUp' styxle={{ textAlign: 'left', fontSize: '30px', color: '#00207b' }}>Pre Galvanized Tubes & Pipes</h4> */}
                                <h6 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '20px' }}>SPECIFICATIONS & SIZE CHART</h6>
                                <div className='slider-para wow fadeInUp' style={{ textAlign: 'left', color: '#000', margin: '0px' }}>
                                    <ul className="bullet">
                                        <li>Galvanic Protection - 5 Time Longer Life from Other Pipes</li>
                                        <li>Stronger, Lighter More Durable</li>
                                        <li>Strength - Pre Galvanized tubes are having Higher tensile strength</li>
                                        <li>Outstanding Corrosion Protection</li>
                                        <li>Weld Friendliness</li>
                                        <li>Exceptional Surface Quality</li>
                                        <li>Less Maintenance Means Lower Cost</li>
                                    </ul>
                                    {/* Galvanic Protection - 5 Time Longer Life from Other Pipes<br />
                                    Stronger, Lighter More Durable<br />
                                    Strength - Pre Galvanized tubes are having Higher tensile strength<br />
                                    Outstanding Corrosion Protection<br />
                                    Weld Friendliness<br />
                                    Exceptional Surface Quality<br />
                                    Less Maintenance Means Lower Cost */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='about-section-4 bottom padding-left-right team-section-1' style={{ background: 'rgb(250 249 249)' }}>
                <div className='container'>
                    <div className='header-title header-center mb-0'>
                        <h2 className='heading'>PRODUCT APPLICATIONS</h2>
                    </div>
                    <div className='row bottom top'>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/gp-pipes/1.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/gp-pipes/2.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/gp-pipes/3.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/gp-pipes/4.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/gp-pipes/5.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/gp-pipes/6.webp')} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GsPipes;