import React, { useState } from "react";
import { GoogleMap, InfoWindowF, MarkerF, useJsApiLoader } from "@react-google-maps/api";

const markers = [
    {
        id: 1,
        name: "C-72/1/2, Lohamandi ,Industrial Area-  Ghaziabad - UP, Contact No : 8130191521",
        position: { lat: 28.6692, lng: 77.4538 }
    },
    {
        id: 2,
        name: "Plot -88 Prakash Industrial Estate, Sahibabd - Ghaziabad, Contact No : 8130191517",
        position: { lat: 28.6620, lng: 77.3367 }
    },
    {
        id: 3,
        name: "Plot No.D-37, Gopalpur, UPSIDA, Near Jain Dharam Kanta , Sikandrabad - UP, Contact No : 8510007640",
        position: { lat: 28.452393, lng: 77.697205 }
    },
    {
        id: 4,
        name: "Plot No.265/1, 268/1, Behind  J K Video Hall, Dharsiwa - Raipur, Contact No : 7489924744, 7489924741",
        position: { lat: 21.563768, lng: 81.6995198 }
    },
    {
        id: 5,
        name: "74, Devguradia Road, Vyapar Vikas Parisar, Indore, Contact No : 7987301846",
        position: { lat: 22.719568, lng: 75.857727 }
    },
    {
        id: 6,
        name: "5-4-23/306, 307, 3rd Floor, Ispat Bhawan, Distellery Road, Ranigunj , secunderabad - Hyderabad, Contact No : 9032002475",
        position: { lat: 17.4065, lng: 78.4772 }
    },
    {
        id: 7,
        name: "Door No-76-16-9/A, Bhawanipuram - Vijayavada, Contact No : 9328828085",
        position: { lat: 16.515099, lng: 77.6006 }
    },
    {
        id: 8,
        name: "A-72, Block -B, Autonagar, Vizag - AP, Contact No : 9329928080",
        position: { lat: 17.6962, lng: 83.1874 }
    },
    {
        id: 9,
        name: "D.NO. 78/2 & 78/3, Beside kesava Reddy School, Near Vediampeta - Anantapur, Contact No : 9032002473",
        position: { lat: 14.6819, lng: 77.6006 }
    },
    {
        id: 10,
        name: "Plot No-40, Badadanpur, Puri By Pass Road - Bhubaneswar, Contact No : 9329928089",
        position: { lat: 19.81418, lng: 85.83095 }
    },
];


function MapInclud() {
    const [activeMarker, setActiveMarker] = useState(null);
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyApDFI-6yxI-xgq-pPzIlV0SUDYkaAMmec"
    });

    const handleActiveMarker = (marker) => {
        setActiveMarker(marker === activeMarker ? null : marker);
    };

    const handleOnLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        markers.forEach(({ position }) => bounds.extend(position));
        map.fitBounds(bounds);
    };



    return (
        <GoogleMap
            onLoad={handleOnLoad}
            onClick={() => setActiveMarker(null)}
            mapContainerStyle={{ width: "100%", height: "500px" }}
        >
            {markers.map(({ id, name, position }) => (
                <MarkerF
                    key={id}
                    position={position}
                    className='closebutton'
                    onClick={() => handleActiveMarker(id)}
                >
                    {activeMarker === id ? (
                        <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                            <div className="location-marker">{name}</div>
                        </InfoWindowF>
                    ) : null}
                </MarkerF>
            ))}
        </GoogleMap>
    );
}

export default MapInclud;