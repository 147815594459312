import React, { useEffect, useRef } from 'react'
import { useParallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import ImageInner from '../../assets/images/products/channel-beam-banner.webp';


export default function ChannelBeam() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <section className='inner-banner' style={{ backgroundImage: `url(${ImageInner})` }}>
                <h1 className='heading'>Channel Beam</h1>
                <div className='container'>
                    <p style={{ textAlign: 'center' }}>Channels and beams are structural steel components essential in construction. <br />Channels, with a C-shaped cross-section, offer load-bearing capabilities for frameworks. Beams, typically I- or H-shaped, provide strength and stability to support structures and distribute loads efficiently.</p>
                </div>
            </section>

            <section className='about-section-4 bottom_100px top padding-left-right team-section-1' style={{ background: '#0b164e' }}>
                <div className='container'>
                    <div className='service-detail-left-side'>
                        <h3 className='sub-heading bottom text-center' style={{ color: 'white' }}> OUR PRODUCT</h3>

                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='three-images-container'>
                                    <div className='first-img-box'>
                                        <img src={require('../../assets/images/products/channel-beam-in.webp')} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='service-details-right-content'>
                                    <p className="with-line">Channels, also known as C-channels, are steel profiles with a C-shaped cross-section. They are commonly used in construction for their strength and versatility..</p>
                                    <p className="with-line">Beams are horizontal structural elements designed to support loads and transfer them to vertical supports or columns. They come in various shapes, including I-beams (or H-beams), T-beams, and box beams.</p>
                                    <p className="with-line"><b>Channels</b> are characterized by their C-shaped cross-section, featuring two parallel flanges connected by a central web. This design provides effective support and stability for various applications, such as framing and bracing.</p>
                                    <p className="with-line"><b>Beams</b>, particularly I-beams (or H-beams), have an I-shaped cross-section with two parallel flanges connected by a central web. This configuration optimizes strength while reducing weight, making beams ideal for supporting significant horizontal loads in frameworks, floors, and roofs.</p>
                                    <p className='with-line'>Both channels and beams are engineered to maximize load-bearing capacity, with their specific shapes tailored to handle different structural stresses and applications efficiently.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className='main-heading' style={{ color: 'white' }}>Benefits of Channels and Beams</h3>
                    <p style={{ color: 'white' }}><strong>Load-Bearing Capacity:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}> Both channels and beams are designed to support substantial loads, making them essential for maintaining structural integrity and stability.</li>
                    </ul>

                    <p style={{ color: 'white' }}><strong>Strength and Durability:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>Made from high-quality steel, channels and beams offer excellent strength and durability, ensuring long-lasting performance in various construction applications.</li>
                    </ul>
                    <p style={{ color: 'white' }}><strong> Versatility:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>Channels and beams can be customized to meet specific design requirements, allowing for flexibility in construction and fabrication.</li>
                    </ul>
                    <p style={{ color: 'white' }}><strong>Ease of Fabrication:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>Steel channels and beams are relatively easy to cut, weld, and assemble, facilitating efficient construction and structural modifications.</li>
                    </ul>
                    <p style={{ color: 'white' }}><strong>Cost-Efficiency: </strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>The use of steel channels and beams offers a cost-effective solution for structural reinforcement, providing a balance between strength and expense. </li>
                    </ul>
                </div>
            </section>

            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container'>
                    <div className='product-contact-redirect'>
                        <Link to="/contact">
                            <img src={require('../../assets/images/products/contact.webp')} alt="" />
                            <h3 className='main-heading-inquery'>Inquiry Now</h3>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}
