import React, { useEffect, useRef } from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import WOW from 'wowjs';

import { useParallax } from 'react-scroll-parallax';
import ServiceInclude from './ServiceInclude';
import { Link } from 'react-router-dom';
import Whychooseus from '../../component/Whychooseus';
import InnerImage from '../../assets/images/products/hr-sheet.webp';

export default function HrSheet() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <section className='inner-banner' style={{ backgroundImage: `url(${InnerImage})` }}>

                <h1 className='heading'>HR Sheet</h1>
                <div className='container'>
                    <p style={{ textAlign: 'center' }}>HR (Hot-Rolled) sheets are steel sheets produced by rolling heated steel billets, offering significant strength and durability.<br />
                        They are easier to form and shape compared to cold-rolled sheets, making them ideal for construction and fabrication.<br />
                        Commonly used in structural components, automotive parts, and industrial applications.</p>
                </div>
            </section>

            <section className='about-section-4 bottom_100px top padding-left-right team-section-1' style={{ background: '#0b164e' }}>
                <div className='container'>
                    <div className='service-detail-left-side'>
                        <h3 className='sub-heading bottom text-center' style={{ color: 'white' }}> OUR PRODUCT</h3>

                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='three-images-container'>
                                    <div className='first-img-box'>
                                        <img src={require('../../assets/images/products/hr-steel-sheet.webp')} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='service-details-right-content'>
                                    <p className="with-line"><b>Construction:</b> HR Sheets are used in the construction of bridges, buildings, and industrial frameworks. Their strength and formability make them suitable for structural components, reinforcements, and load-bearing applications.</p>
                                    <p className="with-line"><b>Fabrication:</b> In fabrication, HR Sheets are employed for making containers, heavy machinery parts, and structural supports. Their ease of machining and welding ensures efficiency in assembly and customization.</p>
                                    <p className="with-line"><b>Automotive Industry:</b> HR Sheets are used in the automotive sector for manufacturing body panels, frames, and chassis parts where strength and flexibility are required.</p>
                                    <p className="with-line">In summary, HR Sheets are an essential material in construction and fabrication due to their strength, flexibility, and cost-effectiveness. Their adaptability to different applications and processes makes them a vital component in various industries.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className='main-heading' style={{ color: 'white' }}>Features of HR Sheet</h3>
                    <p style={{ color: 'white' }}><strong>Durability:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>HR Sheets are known for their strength and durability, making them ideal for structural applications in construction and heavy-duty fabrication. Their ability to withstand heavy loads and environmental stresses ensures long-lasting performance in various industrial and outdoor settings.</li>
                    </ul>

                    <p style={{ color: 'white' }}><strong>Cost-Effectiveness:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>The hot rolling process used to manufacture HR Sheets is more economical compared to cold-rolled steel production. This results in lower material costs, making HR Sheets a cost-effective option for large-scale construction and fabrication projects without compromising on quality.</li>
                    </ul>
                    <p style={{ color: 'white' }}><strong>Consistent Quality:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>HR Sheets are produced using controlled hot-rolling processes that ensure consistent thickness, dimensions, and mechanical properties. This reliability guarantees uniform quality across batches, making them suitable for applications where precision and consistency are critical.</li>
                    </ul>
                    <p style={{ color: 'white' }}><strong>Weld Integrity:</strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>HR Sheets are easily weldable due to their composition and smooth surface, ensuring strong and reliable weld joints. This property is essential for fabrication and assembly, allowing for secure and durable connections in structural and industrial applications.</li>
                    </ul>
                    <p style={{ color: 'white' }}><strong>Versatility: </strong></p>
                    <ul>
                        <li style={{ color: 'white' }}>HR Sheets come in a wide range of thicknesses, sizes, and finishes, making them highly versatile for various applications. Whether for construction, fabrication, or automotive use, HR Sheets can be formed, cut, and customized to meet specific project requirements. </li>
                    </ul>
                </div>
            </section>

            <section className='about-section-4 bottom_100px top padding-left-right team-section-1'>
                <div className='container'>
                    <div className='product-contact-redirect'>
                        <Link to="/contact">
                            <img src={require('../../assets/images/products/contact.webp')} alt="" />
                            <h3 className='main-heading-inquery'>Inquiry Now</h3>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}
