import React, { useEffect } from "react";
import { Parallax, useParallax } from 'react-scroll-parallax';
import InnerImage from '../../assets/images/about-check.webp';
import PdfSize from '../../assets/images/size.pdf';

const AngleChannelBeam = () => {

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <section ref={parallax.ref} className="about-section-4 top bottom padding-left-right team-section-1">
                <div className="container">
                    <div className="value-product-image-logo">
                        <h4 className='sub-heading wow fadeInUp' style={{ color: '#00207b' }}>Angle Channel Beam</h4>
                    </div>
                    <hr class="wavy-divider" />
                    <div className="row">
                        <div className="col-md-5">
                            <div className="product-value-image">
                                <img src={require('../../assets/images/home-product/angle-channel-beam.webp')} alt="single door" />
                            </div>
                        </div>
                        <div className="col-md-7 top">
                            <div className="product-value-right top">
                                <h4 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '30px', color: '#00207b' }}>Angle, Channel, Beam</h4>
                                <h6 className='sub-heading wow fadeInUp' style={{ textAlign: 'left', fontSize: '20px' }}>SPECIFICATIONS AND SIZE CHART</h6>
                                <div className='slider-para wow fadeInUp' style={{ textAlign: 'left', color: '#000', margin: '0px' }}>
                                    <a
                                        className="explore-btn"
                                        href={PdfSize}
                                        download
                                        style={{ textDecoration: 'none' }}
                                    >
                                        Download Chart
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='about-section-4 bottom padding-left-right team-section-1' style={{ background: 'rgb(250 249 249)' }}>
                <div className='container'>
                    <div className='header-title header-center mb-0'>
                        <h2 className='heading'>PRODUCT APPLICATIONS</h2>
                    </div>
                    <div className='row bottom top'>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/channel-beam/channel-1.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/channel-beam/channel-2.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src={require('../../assets/images/home-product/channel-beam/channel-3.webp')} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className='value-product-logo'>
                                <img src="https://admin.aplapollo.com/uploads/4_1_6107c59b7e.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AngleChannelBeam;