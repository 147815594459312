
import React, { useEffect, useRef } from 'react'
import CountUp from 'react-countup';
import { Button, Label } from 'reactstrap';
import { Parallax, useParallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import RightSection from './RightSection';
import InnerImage from '../../assets/images/about-banner.webp';
import BlogVideo from '../../assets/images/blog_video.mp4';


export default function BrandStreetIndiaandWhyStayCalm() {
    const swiperRef1 = useRef();

    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <section className='inner-banner' style={{ backgroundImage: `url(${InnerImage})` }}>
                <h1 className='heading'>Blog</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Blog</li>
                    </ol>
                </nav>
            </section>


            <section className='about-section-4 top padding-left-right team-section-1'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-2'>
                            {/* <RightSection /> */}
                        </div>
                        <div className='col-lg-8'>
                            <div className='single-details-page'>
                                <div className='banner-part'>
                                    <video className='web-banner' style={{ width: '100%' }} autoPlay loop muted>
                                        <source src={BlogVideo} type='video/mp4' />
                                    </video>
                                </div>
                                <div className='header-part'>
                                    <ul>
                                        <li>July-2-2024</li>
                                    </ul>
                                </div>
                                <h4 className='heading'>Upcoming latest warehouse in Raipur by Vikash Steel Tube Pvt Ltd.</h4>
                                <p className='paragraph1 mb_10px'>Exciting developments are underway as Vikash Steel Tube Pvt Ltd prepares to launch a new steel plant in Raipur. Set to boost the region's industrial landscape, the plant will focus on storing high-quality steel tubes and pipes for various sectors, including construction, automotive, and infrastructure being as authorised dealer of APL Apollo Tubes Limited .</p>
                                <p className='paragraph1 mb_10px'>With Raipur already being a significant steel hub, the upcoming plant promises to enhance production capacity, create new job opportunities, and contribute to local economic growth. The state-of-the-art facility will leverage cutting-edge technology and the company's extensive industry experience to meet growing market demands for top-tier steel products.</p>
                                <p className='paragraph1 mb_10px'>Vikash Steel Tube Pvt Ltd's new venture reaffirms its commitment to innovation and excellence in the steel industry, strengthening its position as a leader in delivering durable and reliable steel solutions.</p>
                                <p className='paragraph1 mb_10px'>This project is expected to play a key role in both regional and national development by supporting various industries that rely on steel as a critical resource.</p>
                            </div>
                        </div>
                        <div className='col-lg-2'>
                            {/* <RightSection /> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
