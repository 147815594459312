import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import InnerImage from '../assets/images/contact_image.webp';
import Map from './Map';
import { Parallax, useParallax } from 'react-scroll-parallax';

export default function Contact() {


    const parallax = useParallax < HTMLDivElement > ({
        rotateY: [0, 360],
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <section ref={parallax.ref} className='inner-banner' style={{ backgroundImage: `url(${InnerImage})` }}>
                <h1 className='heading'>Contact Us</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className='star'><i class="fa fa-long-arrow-right" aria-hidden="true"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Contact us</li>
                    </ol>
                </nav>
            </section>

            <section className='padding-left-right'>
                <div className='business-body'>
                    <div className='card-box row'>
                        <div className='col-lg-4 col-md-6 wow fadeInUp' data-wow-delay="0.2s">
                            <div className="cards">
                                <div className='line_border'></div>
                                <div className='box-text'>
                                    <img src={require('../assets/images/location.webp')} />
                                    <h4>Corporate Office</h4>
                                    <p> 88, prakash industrial estate , pipe market Sahibabad - Ghaziabad, 201006</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 wow fadeInUp' data-wow-delay="0.4s">
                            <div className="cards">
                                <div className='line_border'></div>
                                <div className='box-text'>
                                    <img src={require('../assets/images/email.webp')} />
                                    <h4>Email us</h4>
                                    <p>sales@vikashsteel.in<br />
                                        info@vikashsteel.in</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 wow fadeInUp' data-wow-delay="0.6s">
                            <div className="cards">
                                <div className='line_border'></div>
                                <div className='box-text'>
                                    <img src={require('../assets/images/telephone.webp')} /><br />
                                    <h4>Call us</h4><br />
                                    <p>18005695695</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            {/* <section className='padding-left-right top location-section'>
                <div className='container-fluid'>
                    <div className='header-title header-center'>
                        <h3 className='sub-heading'>Our Locations</h3>
                    </div>
                    <div className='row mb-40px justify-content-center'>
                        <div className='col-lg-4'>
                            <div className='location_card'>
                                <img src={require('../assets/images/img-city/gurugram.jpg')} alt="" />
                                <div className='location_content'>
                                    <h4>Ghaziabad</h4>
                                    <ul>
                                        <li><p style={{ color: '#0b164e', fontWeight: '600' }}><i className="fa-solid fa-location-pin"></i>North - 3  branches - 1-Pipe Market -  Ghaziabad2- Lohamandi - Ghaziabad 3- Sikandrabad- Bulandshahr.</p></li>
                                        <li><a href='tel:1800 569 5695'><p style={{ color: '#0b164e', fontWeight: '600' }}><i className="fa-solid fa-phone"></i>1800 569 5695</p></a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='location_card'>
                                <img src="https://www.wavecity.in/uploads/blogs/202301310528404.jpg" alt="" />
                                <div className='location_content'>
                                    <h4>Bulandsaher </h4>
                                    <ul>
                                        <li><p style={{ color: '#0b164e', fontWeight: '600' }}><i className="fa-solid fa-location-pin"></i>East - 2  branches - 1- Raipur - Chattisgarh 2- Bhubneshwar - Odisha South-.</p></li>
                                        <li><a href='tel:1800 569 5695'><p style={{ color: '#0b164e', fontWeight: '600' }}><i className="fa-solid fa-phone"></i>1800 569 5695</p></a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='location_card'>
                                <img src="https://www.holidify.com/images/bgImages/SECUNDERABAD.jpg" alt="" />
                                <div className='location_content'>
                                    <h4>Sikandarabad</h4>
                                    <ul>
                                        <li><p style={{ color: '#0b164e', fontWeight: '600' }}><i className="fa-solid fa-location-pin"></i>1- Hyderabad 2- Anantapur 3- Vijayavada West - Indore</p></li>
                                        <li><a href='tel:1800 569 5695'><p style={{ color: '#0b164e', fontWeight: '600' }}><i className="fa-solid fa-phone"></i>1800 569 5695</p></a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className='padding-left-right bottom location-section'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4'>
                            <div className='address-left'>
                                <div className='address-box'>
                                    <h6>Pipe Market Ghaziabad</h6>
                                    <p style={{ fontSize: '14px' }}>Plot -88 Prakash Industrial Estate, Sahibabd - Ghaziabad <br /><b>Contact No : 8130191517</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Lohamandi, Ghaziabad</h6>
                                    <p style={{ fontSize: '14px' }}>C-72/1/2, Lohamandi ,Industrial Area-  Ghaziabad - UP<br /> <b>Contact No : 8130191521</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Sikandrabad</h6>
                                    <p style={{ fontSize: '14px' }}>Plot No.D-37, Gopalpur, UPSIDA, Near Jain Dharam Kanta , Sikandrabad - UP<br /> <b>Contact No : 8510007640</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Raipur</h6>
                                    <p style={{ fontSize: '14px' }}>Plot No.265/1, 268/1, Behind  J K Video Hall, Dharsiwa - Raipur<br /> <b>Contact No : 7489924744, 7489924741</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Indore</h6>
                                    <p style={{ fontSize: '14px' }}>74, Devguradia Road, Vyapar Vikas Parisar, Indore<br /> <b>Contact No : 7987301846</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Odisha</h6>
                                    <p style={{ fontSize: '14px' }}>Plot No-40, Badadanpur, Puri By Pass Road - Bhubaneswar<br /> <b>Contact No : 9329928089</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Hyderabad</h6>
                                    <p style={{ fontSize: '14px' }}>5-4-23/306, 307, 3rd Floor, Ispat Bhawan, Distellery Road, Ranigunj, Sicundarabad - Hyderabad<br /> <b>Contact No : 9032002475</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Vijayavada</h6>
                                    <p style={{ fontSize: '14px' }}>Door No-76-16-9/A, Bhawanipuram - Vijayavada<br /> <b>Contact No : 9328828085</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Vizag</h6>
                                    <p style={{ fontSize: '14px' }}>A-72, Block -B, Autonagar, Vizag - AP<br /> <b>Contact No : 9329928080</b></p>
                                </div>
                                <div className='address-box'>
                                    <h6>Anantapur</h6>
                                    <p style={{ fontSize: '14px' }}>D.NO. 78/2 & 78/3, Beside kesava Reddy School, Near Vediampeta - Anantapur<br /> <b>Contact No : 9032002473</b></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8'>
                            <Map />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
